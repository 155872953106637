<template>
  <b-card>
    <div
      v-if="isLoading"
      class="d-flex justify-content-center align-items-center"
    >
      <b-spinner
        type="grow"
        variant="warning"
        class="mr-1"
      />
      <span class="loading-text">Loading...</span>
    </div>
    <div v-else>
      <div class="border-b mb-2 d-flex justify-content-between align-items-center">
        <span class="card-title">{{ year_month }}</span>
        <div class="date-picker">
          <flat-pickr
            v-model="year_month"
            class="form-control"
            :config="{ mode: 'single', dateFormat: 'Y-m'}"
          />
          <span
            class="search-text"
            @click="handleSerach"
          >搜索</span>
        </div>
      </div>
      <span
        v-if="list && list.length === 0"
        class="no-data"
      >暂无数据</span>
      <b-table
        v-else
        responsive
        bordered
        :items="list"
        :fields="fields"
      >
        <template #cell(total_chargeable_weight)="data">
          <span>{{ Number(data.value).toFixed(2) }}</span>
        </template>
        <template #cell(action)="data">
          <span
            class="watch"
            @click="handleWatch(data.item)"
          >查看</span>
          <span
            class="download"
            @click="handleDownload(data.item)"
          >下载</span>
        </template>
      </b-table>
    </div>
  </b-card>
</template>

<script>
import {
  BTable, BCard, BSpinner,
} from 'bootstrap-vue'
import dayjs from 'dayjs'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BTable, BCard, BSpinner, flatPickr,
  },
  data() {
    return {
      isLoading: false,
      list: [],
      fields: [
        {
          key: 'agent_name', label: '代理点名称', sortable: true,
        },
        {
          key: 'total_shipment_amount', label: '合计运单数量', sortable: true,
        },
        {
          key: 'total_package_amount', label: '合计包裹数量', sortable: true,
        },
        {
          key: 'total_chargeable_weight', label: '合计计费数量', sortable: true,
        },
        {
          key: 'action', label: '操作',
        },
      ],
      year_month: '',
    }
  },
  created() {
    this.year_month = dayjs().format('YYYY-MM')
    this.handleInit()
  },
  methods: {
    handleSerach() {
      this.handleInit()
    },
    async handleInit() {
      try {
        this.isLoading = true
        const data = await this.$api.statisticsSelfAgentsByYearmonth({ year_month: this.year_month })
        this.isLoading = false
        if (data.code === 200) {
          this.list = data.data.statistics_of_agents
        }
      } catch (error) {
        this.isLoading = false
        console.log('error', error)
      }
    },
    handleWatch(item) {
      this.$router.push(`/adminstatistics/detail/${item.agent_id}?year_month=${this.year_month}&self_agent_name=${item.agent_name}`)
    },
    async handleDownload(item) {
      try {
        const params = {
          self_agent_name: item.agent_name,
          self_agent_id: item.agent_id,
          year_month: this.year_month,
        }
        this.$store.dispatch('shipment/handleAdminExport', params)
      } catch (error) {
        console.log('error', error)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.watch, .download{
    cursor: pointer;
    font-size: 18px;
    color: #FFAF2A;
}
.download {
    color: green;
    margin-left: 10px;
}
.date-picker{
  width: 20%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search-text{
  min-width:40px;
  margin-left:10px;
  color: #FFAF2A;
  cursor: pointer;
}
</style>
